<template>
  <div id="forgot">
    <div class="form-header">
      <h3>Ați uitat parola?</h3>
      <p class="text-muted">Introduceți adresa de email pentru a vă reseta parola:</p>
    </div>

    <b-form class="form" novalidate v-on:submit.prevent="submit()">
      <b-form-group>
        <b-form-input
          name="email"
          type="text"
          class="form-control-solid"
          placeholder="Adresă de email"
          v-model="email"
          v-bind:trim="true"
          v-bind:state="state.email"
        />

        <b-form-invalid-feedback id="email-feedback">
          <span v-if="!$v.email.required">Adresa de email este obligatorie.</span>
          <span v-if="!$v.email.email">Adresa de email este incorectă.</span>
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="form-group d-flex flex-wrap justify-content-between">
        <button type="button" class="btn btn-light-danger" v-on:click="showLogin()">Renunță</button>
        <button type="submit" class="btn btn-primary">Resetează parola</button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { helpers, email, required } from "vuelidate/lib/validators";

import { isNullOrEmpty } from "@/core/utils";

function mapValidationState(state) {
  if (state.$dirty) {
    if (state.$error) {
      return false;
    }

    if (!isNullOrEmpty(state.$model)) {
      return true;
    }
  }

  return null;
}

export default {
  mixins: [validationMixin],

  data() {
    return {
      email: null,
    };
  },

  validations: {
    email: {
      email,
      required,
    },
  },

  computed: {
    state() {
      return {
        email: mapValidationState(this.$v.email),
      };
    },
  },

  methods: {
    showLogin() {
      this.$emit("show-login");
    },

    submit() {
      this.$v.$touch();

      if (this.$v.$anyError) return;
    },
  },
};
</script>

<style lang="scss">
#forgot {
  @import "@/assets/sass/pages/auth/common.scss";

  padding-bottom: 5.9rem;

  .form-control {
    margin-bottom: 0.5rem;
  }

  button {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
