<template>
  <div id="login-root" class="d-flex flex-root flex-center">
    <div class="text-center">
      <div class="login-header d-flex flex-center">
        <img class="logo" src="media/logos/logo-128.png" alt="" />
      </div>

      <LoginWidget v-show="loginVisible" v-on:show-forgot="showForgot" />
      <ForgotWidget v-show="forgotVisible" v-on:show-login="showLogin" />
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";

import { mapGetters } from "vuex";

import KTUtil from "@/assets/js/components/util";

import LoginWidget from "@/view/content/widgets/auth/Login.vue";
import ForgotWidget from "@/view/content/widgets/auth/Forgot.vue";

export default {
  components: {
    LoginWidget,
    ForgotWidget,
  },

  data() {
    return {
      form: "login",
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn"]),

    loginVisible() {
      return this.form === "login";
    },

    forgotVisible() {
      return this.form === "forgot";
    },
  },

  watch: {
    form(newForm) {
      KTUtil.animateClass(jQuery(`#${newForm}`)[0], "animate__animated animate__fadeIn");
    },
  },

  created() {
    if (this.isLoggedIn) {
      this.$router.push({ name: "home" });
    }
  },

  methods: {
    showLogin() {
      this.form = "login";
    },

    showForgot() {
      this.form = "forgot";
    },
  },
};
</script>

<style lang="scss">
#login-root {
  background: url('/media/bg/bg-3.jpg') no-repeat center top/cover;

  .login-header {
    margin-bottom: 2.5rem;

    .logo {
      max-height: 100px;
    }
  }
}
</style>
